// Headings
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.type-jumbo,
.type-jumbo-xl {
  display: block;
  margin-bottom: 0.5em;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

@for $i from 1 through 6 {
  .h#{$i},
  h#{$i} {
    @include get-font-size(#{h#{$i}});
  }
}

small,
.small {
  font-size: $small-font-size;
  font-weight: $font-weight-normal;
}

.type {
  &-body-lg {
    @include get-font-size(body-lg);

    line-height: 1.6;
  }

  &-body-md {
    @include get-font-size(body-md);

    line-height: 1.6;
  }

  &-body-xs {
    @include get-font-size(body-xs);
  }

  &-jumbo {
    @include get-font-size(jumbo);
  }
}

h4, .h4 {
  font-weight: bold;
}