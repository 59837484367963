.button-with-icon {
  padding-right: rem($button-padding-x * 2.8);
  padding-left: rem($button-padding-x/2);
  margin-right: rem(8px);

  .icon-svg {
    @include size(1em);
  }

  fstr-icon {
    pointer-events: none;
  }
}

.button-icon {
  @include size(56px);
  background-color: $white;
  border-radius: rem(200px);
  display: flex;
  justify-content: center;
  padding: 0;
  transition: fill 0.35s ease;

  .icon {
    @include size(24px, 24px);
  }

  fstr-icon {
    pointer-events: none;
  }

  &:hover {
    fill: lighten($black, 50%);
  }
}

.button-icon-link {
  .icon {
    top: -5px;
    transition: fill 0.35s ease;
  }
  fstr-icon {
    pointer-events: none;
  }

  &:hover .icon {
    fill: lighten($black, 50%);
  }
}
