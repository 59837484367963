$button-size: 40px;
$button-padding-x: 22px;
$font-baseline-offset: 5px;

.button {
  @extend %button-reset;

  display: inline-flex;
  position: relative;
  height: rem($button-size);
  align-items: center;
  padding-right: rem($button-padding-x);
  padding-left: rem($button-padding-x);
  overflow: hidden;
  font-family: $headings-font-family;
  font-weight: 700;
  line-height: 1; // rem($button-size + $font-baseline-offset)
  text-decoration: none;
  transition: all 200ms ease-in-out;
  background-color: $white;
  color: theme-color(primary);
  border-radius: 0;
  text-transform: uppercase;

  @include media-breakpoint-up(md) {
    height: rem($button-size * 1.2);
    padding-right: rem($button-padding-x * 1.2);
    padding-left: rem($button-padding-x * 1.2);
  }

  .icon * {
    fill: theme-color(primary);
    color: theme-color(primary);
    transition: fill 0.2s;
  }

  @media (hover: hover) {
    &:hover {
      background-color: lighten(theme-color(primary), 40%);
      // color: lighten($black, 10%);

      // .icon * {
      //   fill: lighten($black, 10%);
      // }
    }
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  span + fstr-icon,
  fstr-icon + span {
    margin-left: rem(10px);
  }

  &-link,
  &-icon,
  &-with-icon {
    background: transparent;
    color: $black;

    .icon * {
      fill: $black;
      transition: fill 0.2s;
    }

    @media (hover: hover) {
      &:hover {
        background: transparent;
        color: lighten($black, 10%);

        .icon * {
          fill: lighten($black, 10%);
        }
      }
    }
  }
}
