::selection {
  background-color: lighten(theme-color(primary), 20%);
}

a {
  display: inline-block;
  transition: $transition-base;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

svg {
  display: block;
}

pre {
  white-space: pre-wrap;
}

hr,
.hr {
  margin-top: $hr-margin-y;
  margin-bottom: $hr-margin-y;
  border: 0;
  border-top: $border-width solid $border-color;

  &--lg {
    border-top: $border-width-lg solid $border-color;
  }

  &--light {
    border-color: $border-color-light;
  }
}

p,
.p {
  @include get-font-size(body);
}
