$white: #fff;
$black: #000;

$colors: (
  blue: #007bff,
  indigo: #6610f2,
  purple: #6f42c1,
  pink: #e83e8c,
  red: #dc3545,
  orange: #ff6600,
  yellow: #ffc107,
  green: #26BA5E,
  teal: #20c997,
  cyan: #17a2b8,
);

$theme-colors: (
  primary: color(orange),
  // Primary tints from 100-900:
  // primary-100: #123456,
  secondary: color(blue),
  // Secondary tints from 100-900:
  // secondary-100: #123456,
  success: color(green),
  info: color(blue),
  warning: color(orange),
  danger: color(red),
  light: $white,
  dark: $black,
);

$grays: (
  // Overwrite these grays from 100-900,
  // or add your own gray keys.
  900: #212529,
  800: #343a40,
  700: #5A5A5A,
  600: #6c757d,
  500: #adb5bd,
  400: #ced4da,
  300: #dee2e6,
  200: #f0f0f0,
  100: #f8f9fa
);
