:root {
  #{--primary-color}: theme-color(primary);
  #{--secondary-color}: theme-color(secondary);
  #{--success-color}: theme-color(success);
  #{--info-color}: theme-color(info);
  #{--warning-color}: theme-color(warning);
  #{--danger-color}: theme-color(danger);
  #{--light-color}: theme-color(light);
  #{--dark-color}: theme-color(dark);
}
