.movement {
  @include media-breakpoint-up(lg) {
    padding: 0 50px;
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: rem(24px) 0;
    position: relative;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    &:not(:last-child) {
      &::before {
        @include abs(null, null, 0, null);
        content: '';
        background-color: gray-color(200);
        height: 1px;
        width: 100%;
      }
    }
  }
  &__label {
    margin-bottom: 0;

    @include media-breakpoint-up(lg) {
      max-width: 420px;
    }

    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }

  &__time {
    display: flex;
    flex-direction: row;

    @include media-breakpoint-down(sm) {
      width: 100%;
      justify-content: space-between;
      margin-top: $spacer;
      
      &.is--multiple {
        justify-content: center;
      }
    }


    .mat-form-field {
      width: 80px;

      @include media-breakpoint-down(sm) {
        width: 48%;
      }

      &:first-child {
        margin-right: $spacer;
      }
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
  }
}
