.score-container {
  margin: 0 rem(-30px);
  padding: rem(30px) rem(30px) rem(50px);
  
  @include media-breakpoint-up(sm) {
    min-height: 20vh;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
}

// @keyframes slideIn {
//   from { transform: translateX(0) }
//   to { transform: translateX(var(--score)) }
// }

.score-meter {
  $height: 25px;

  background: linear-gradient(90deg, #FF2D2D -1.66%, rgba(255, 201, 64, 0.84) 50.69%, #4CD35D 100%);
  border: 4px solid #FFFFFF;
  border-radius: 42px;
  display: inline-block;
  height: $height;
  width: 100%;
  position: relative;
  max-width: 500px;

  &__pointer {
    @include abs(0);

    width: 8px;
    height: $height * 1.5;
    background-color: black;
    border-radius: 10px;
    border: 1px solid gray-color(800);
    transform: translate(0, -50%);
    top: 50%;
    box-shadow: 1px 2px 8px 0px rgba(black, .1);
    transition: left .5s cubic-bezier(0.54, 0.14, 0.54, 1.19);
    will-change: left;

    &:after {
      content: attr(data-label);
      position: absolute;
      bottom: calc(-1em - 19px);
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      background: white;
      border-radius: 20px;
      padding: 2px 10px;
      box-shadow: 1px 2px 8px 0px rgba(black, .1);
      color: theme-color(primary);
      opacity: 0;
      transition: all .2s .6s cubic-bezier(0.54, 0.14, 0.54, 1.19);
      transform: scale(.75) translate(-50%, 0px);
      transform-origin: center;
    } 

    &.is--animating:after {
      opacity: 1;
      transform: scale(1) translate(-50%, 0px);
    }
  }
} 