// Base
$default-font-size: 18px;
$rfs-minimum-font-size: rem($default-font-size); // always use rem unit
$rfs-base-font-size: rem($default-font-size); // always use rem unit
$transition-base: all 0.15s ease-in-out;
$font-size-base: strip-units($default-font-size); // overwrites default bootstrap variable with stripped font-size

// Body
$body-bg: $white;
$body-color: gray-color(900);

// Links
$link-color: theme-color(primary);
$link-decoration: underline;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: underline;

// Default font stack (used on <body>)
$font-family-sans-serif: 'Open Sans', sans-serif;

// Headings
$headings-font-family: 'Blender', serif;
$headings-font-family-secondary: $headings-font-family;
$headings-font-weight: 600;
$headings-line-height: 1;
$headings-color: $black;

// Container
$container-max-widths: (
  xxl: 1600px,
  xl: 1200px,
  md: 1100px,
  xs: 960px,
  xss: 800px,
);

// Spacing
$spacer: 1rem;
$spacers: (
  5: (
    $spacer * 2,
  ),
  6: (
    $spacer * 2.25,
  ),
  8: (
    $spacer * 4,
  ),
);

// Form
$placeholder-font-family: $font-family-sans-serif;
$placeholder-color: gray-color(500);

// Printing
$print-page-size: a4;
