.radio-button-group {
  display: flex;
  flex-direction: column;
}

.radio-button {
  &:not(:first-child) {
    margin-top: $spacer;
  }
}
