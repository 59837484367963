.intro {
  background-color: theme-color(primary);
  padding: 40px;
  max-width: 600px;
  margin-top: 5vh;

  &__subtext {
    font-style: italic;
    font-size: .8em;
  }
  
  @include media-breakpoint-up(sm) {
    padding: 60px;
  }
}