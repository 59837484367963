.gender {
  display: flex;
  justify-content: center;
}

.gender-card {
  margin: 0 10px;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid gray-color(200);
  transition: background .2s ease, border-color .2s ease, transform .2s ease;
  cursor: pointer;

  @include media-breakpoint-up(sm) {
    margin: rem(20px) rem(10px);
    padding: 30px;
  }

  &__label {
    text-transform: uppercase;
    font-weight: 600;
    width: 100%;
    display: inline-block;
    text-align: center;
    color: gray-color(700);

    @include media-breakpoint-up(sm) {
      margin-top: 10px;
    }
  }

  &__figure {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      background: rgba(theme-color(primary), 0.25);;
      border-radius: 50%;
      z-index: 0;
      transition: background .2s ease;
    }

    img {
      z-index: 2;
      position: relative;
    }
  }

  &.is--active {
    background: theme-color(primary);
    box-shadow: 0px 3px 9px rgba($black, 0.15);
    border-color: theme-color(primary);

    .gender-card__label {
      color: $white;
    }

    .gender-card__figure::before {
      background-color: rgba($white, 0.25);
    }
  }

  @media (hover: hover) {
    &:not(.is--active):hover {
      transform: scale(1.05);
      background-color: gray-color(200);
    }
  }

  input[type=radio] {
    height: 0;
    display: block;
  }
}