fstr-icon,
.icon {
  position: relative;
  display: inline-flex;
  min-width: 1em;
  min-height: 1em;
  width: 1em;

  &-svg {
    @include abs(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    fill: inherit;
  }
}

fstr-icon {
  .icon {
    height: 100%;
    width: 100%;
  }
}

@each $name, $value in $icon-sizes {
  .icon-#{$name} {
    width: $value;
    height: $value;

    svg {
      @include size(rem($value));
    }
  }
}
