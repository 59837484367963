.app .mat-radio-button {
  background-color: $white;
  border: solid 1px gray-color(300);
  border-radius: 24px;
  transition: background-color 0.2s ease;

  @media (hover: hover) {
    &:hover {
      background-color: gray-color(200);
    }
  }
}

.app .mat-radio-label {
  margin-bottom: 0;
  padding: 10px 16px;
  white-space: normal;
}

.app .mat-radio-label-content {
  padding-left: 12px;
}

.app .mat-radio-checked {
  background-color: theme-color(primary);
  color: $white;
  font-weight: 500;
  border-color: theme-color(primary);

  @media (hover: hover) {
    &:hover {
      background-color: darken(theme-color(primary), 10%);
      border-color: darken(theme-color(primary), 10%);
    }
  }
}

.app .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $white;
}

.app .mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: $white;
}
