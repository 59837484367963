.style-guide > article {
  border-top: $border-width-lg solid gray-color(200);

  &:nth-child(odd) {
    background: gray-color(100);
  }

  .text-muted {
    background: whitesmoke;
    display: inline-block;
    border-radius: 4px;
    padding: 4px 9px;
    margin-bottom: 7px;
    font-size: 14px;
    font-family: monospace;
    border: 1px solid #e4e4e4;
  }
}

.grid-showcase {
  outline: 1px solid color(blue);

  &__inner {
    outline: 1px solid color(pink);
    height: 75vh;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
}

.headings {
  display: flex;

  & > * {
    width: 50%;
  }

  .jumbo-header {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.icon-list {
  display: flex;
  flex-wrap: wrap;

  &__item {
    width: calc(100% / 3);

    fstr-icon {
      margin-right: rem(10px);
    }
  }
}

.button-styles {
  margin-bottom: 0;
  
  &__item {
    &:not(:last-child) {
      padding-bottom: 32px;
    }

    &__normal,
    &__disabled,
    &__hover {
      min-width: 260px;
    }

    &__hover {
      button {
        &::before {
          width: 100%;
        }
      }
    }

    &__hover--alt {
      button {
        overflow: initial;
        &::before {
          @include abs(null, 0, null, auto);
          transform: scale(1.3);
        }
      }
    }
  }

  .type--row {
    display: flex;
    flex-direction: row;
  }
}

.icon-sizes-list {
  li {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }
}