/**
 * Placeholder for a nice aspect ratio wrapper
 *
 * What is aspect ratio?
 * The aspect ratio of an element describes the proportional relationship between its width and its height.
 * Two common video aspect ratios are 4:3 (the universal video format of the 20th century), and 16:9 (universal for
 * HD television and European digital television)
 */

@mixin aspect-ratio ($ratio: 56.25%){
  overflow: hidden;
  position: relative;
  margin: 0;

  &::before {
    display: block;
    padding-top: $ratio;
    content: '';
  }

  img {
    @extend .aspect-ratio-element;
  }
}