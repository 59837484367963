@each $name, $colors in $button-base-styles {
  $font-color: nth($colors, 1);
  $bg-color: nth($colors, 2);

  .button--#{$name} {
    background: $bg-color;
    color: $font-color;
    border-color: theme-color($name);

    .icon * {
      fill: $font-color;
    }

    @media (hover: hover) {
      &:hover {
        background-color: darken($bg-color, 10%);
        border-color: darken($bg-color, 10%);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
        color: $font-color;

        .icon * {
          fill: $font-color;
        }
      }
    }
  }

  .button-link--#{$name},
  .button-with-icon--#{$name},
  .button-icon--#{$name} {
    background: transparent;
    color: $bg-color;

    .icon * {
      fill: $bg-color;
    }

    @media (hover: hover) {
      &:hover {
        color: darken($bg-color, 20%);

        .icon * {
          fill: darken($bg-color, 20%);
        }
      }
    }
  }
}
