.container {
  position: relative;
  max-width: 100%;

  @include media-breakpoint-up(xl) {
    max-width: map-get($container-max-widths, xl);
  }

  @include media-breakpoint-up(xxl) {
    max-width: map-get($container-max-widths, xxl);
  }

  // KCAP specific
  &,
  &-fluid,
  &-xs,
  &-md,
  &-xl {
    padding-right: rem(20px);
    padding-left: rem(20px);

    @include media-breakpoint-up(md) {
      padding-right: rem(80px);
      padding-left: rem(80px);
    }
  }
}

@each $name, $value in $container-max-widths {
  .container-#{$name} {
    max-width: rem($value);
  }
}
