.slider {
  width: 100%;
  padding: 0 20px;
  position: relative;

  &__label {
    @include abs(100%, null, null, null);
    font-weight: 700;
    font-size: .8em;
    font-family: "Blender", serif;
    color: theme-color(primary);

    &.type--start {
      left: 0px;
    }

    &.type--end {
      right: 0px;
    }


    @include media-breakpoint-up(sm) {
      &.type--start {
        left: 63px;
      }

      &.type--end {
        right: 60px;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    padding: 0 60px;
  }

  .mat-slider-horizontal {
    width: 100%;

    .mat-slider-track-wrapper {
      border-radius: 20px;
      height: 18px;

      >* {
        height: 18px;
      }
    }

    .mat-slider-ticks,
    .mat-slider-ticks-container {
      height: 18px;
    }

    .mat-slider-ticks {
      opacity: 1;
      background-image: repeating-linear-gradient(to right, rgba(#C4C4C4, 0.5), rgba(#C4C4C4, 0.5) 2px, transparent 0, transparent);
      height: 6px;
      margin-top: 6px;
      background-size: 10.2041% 2px;
    }

    .mat-slider-track-background {
      background: #F0F0F0;
    }

    .mat-slider-track-fill,
    .mat-slider-thumb-container,
    .mat-slider-thumb-label {
      z-index: 2;
    }

    .mat-slider-track-fill {
      background: #F4CEB4;
    }

    .mat-slider-thumb-container .mat-slider-thumb {
      width: 38px;
      height: 38px;
      right: -19px;
      bottom: -25px;
      transform: none;
      background-color: theme-color(primary);
      border: none;
    }

    &.mat-slider-has-ticks .mat-slider-wrapper::after {
      display: none;
    }

    &:hover .mat-slider-track-background {
      background: darken(#F0F0F0, 5%);
    }
  }
}



.slider-figure {
  margin-top: 30px;
  display: flex;
  justify-content: center;

  &__score {
    font-size: map-get($font-sizes, jumbo);
    color: theme-color(primary);
    font-weight: 700;
    font-family: $headings-font-family;
    width: 2em;
    display: inline-block;
    text-align: left;
    margin-left: 20px;
  }

  &__image {
    position: relative;
    display: inline-block;

    img {
      position: relative;
      z-index: 2;
    }

    &:after {
      $background-size: 90%;

      content: '';
      width: $background-size;
      height: 0;
      padding-bottom: $background-size;
      background: rgba(theme-color(primary), .2);
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      left: 40%;
    }
  }
}