@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

$font-url-thin: '/assets/fonts/blender/BlenderPro-ThinWeb';
$font-url-book: '/assets/fonts/blender/BlenderPro-BookWeb';
$font-url-medium: '/assets/fonts/blender/BlenderPro-MediumWeb';
$font-url-bold: '/assets/fonts/blender/BlenderPro-BoldWeb';
$font-url-heavy: '/assets/fonts/blender/BlenderPro-HeavyWeb';

@font-face {
  font-family: 'Blender';
  src: url('#{$font-url-thin}.eot');
  src: url('#{$font-url-thin}.eot?#iefix') format('embedded-opentype'), url('#{$font-url-thin}.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Blender';
  src: url('#{$font-url-book}.eot');
  src: url('#{$font-url-book}.eot?#iefix') format('embedded-opentype'), url('#{$font-url-book}.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Blender';
  src: url('#{$font-url-medium}.eot');
  src: url('#{$font-url-medium}.eot?#iefix') format('embedded-opentype'), url('#{$font-url-medium}.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Blender';
  src: url('#{$font-url-bold}.eot');
  src: url('#{$font-url-bold}.eot?#iefix') format('embedded-opentype'), url('#{$font-url-bold}.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Blender';
  src: url('#{$font-url-heavy}.eot');
  src: url('#{$font-url-heavy}.eot?#iefix') format('embedded-opentype'), url('#{$font-url-heavy}.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}