.fstr-cookie-notice__title {
    @extend .h4;
}

.fstr-cookie-notice__content {
    font-size: 1rem;

    a {
        // color: 
    }
}

.app .fstr-cookie-notice__toggle-row__content {
    font-size: .8em;

    p {
        font-size: inherit;
    }
}

.app .fstr-cookie-notice {
    max-width: 500px;
    border-width: 0;
    box-shadow: $box-shadow-lg;
    padding: $spacer*2;

    .toggle__label-text {
        font-size: .9em;
    }

    @include media-breakpoint-down(sm) {
        top: 20px;

        .toggle-button {
            display: none;
        }
    }

    .button-group {
        display: flex;
        justify-content: space-between;

        @include media-breakpoint-down(sm) {
            flex-direction: column;

            .button {
                width: 100%;
                margin-top: 1ex;
                margin-right: 0 !important;
                justify-content: center;
            }
        }
    }
}