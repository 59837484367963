.age {
  &__radio-group {
    justify-content: center;
  }
}

.age-card {
  margin: 8px;
  padding: 20px;
  text-align: center;
  min-width: rem(222px);
  border: 1px solid gray-color(200);
  transition: background 0.2s ease, border-color 0.2s ease, transform 0.2s ease;
  cursor: pointer;

  &.is--active {
    background: theme-color(primary);
    box-shadow: 0px 3px 9px rgba($black, 0.15);
    border-color: theme-color(primary);

    .age-card__value,
    .age-card__label {
      color: $white;
    }
  }

  &__value {
    font-size: rem(34px);
    font-weight: 600;
    width: 100%;
    display: inline-block;
    text-align: center;
    margin-bottom: 0;
  }

  &__label {
    font-size: map-get($font-sizes, h6);
    color: gray-color(500);
  }

  @media (hover: hover) {
    &:not(.is--active):hover {
      transform: scale(1.05);
      background-color: gray-color(200);
    }
  }

  input[type='radio'] {
    height: 0;
    display: block;
  }
}
