.quiz {
  background-color: white;
  max-width: 800px;
  margin: auto;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15), 0px 14px 18px rgba(0, 0, 0, 0.05);

  &-wrapper {
    position: relative;
    overflow: hidden;
    min-height: 60vh;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }

  &__header {
    padding: rem(30px) rem(30px) rem(0);
  }

  &__body {
    padding: rem(10px) rem(30px) rem(30px);
  }

  &__feedback {
    @include abs(0, 0, 0, 0);
    z-index: 10;
    background-color: rgba($black, 0);
    display: flex;
    align-items: center;
    transition: background 0.2s;
    pointer-events: none;

    &.is--open {
      background-color: rgba(black, 0.2);
      pointer-events: auto;
    }

    &__inner {
      background: white;
      padding: 20px 30px;
      text-align: center;
      min-height: 65%;
      margin: 0 20px;
      transition: all 0.2s;
      display: flex;
      flex-flow: column;
      justify-content: center;
      width: 100%;

      @include media-breakpoint-up(sm) {
        margin: 0 50px;
      }
    }
  }

  &__footer {
    padding: rem(30px);
    background-color: gray-color(200);
    box-shadow: 0px -1px 0px rgba($black, 0.13);
  }
}

@include media-breakpoint-up(md) {
  .page-title {
    margin-top: -1em;
  }
}

.single-input {
  &.mat-form-field {
    width: 100%;
    max-width: 400px;
    margin: auto;
    display: block;
  }
}
