// Custom base config

$img-path: '~@/assets/img/'; // background-image: img-path('image.jpg');
$section-max-value: 80; // this will result in a max margin/padding of 100 pixels;

// buttons
$button-base-styles: (
  // name       font-color                  background-color
  primary: (theme-color(light), theme-color(primary)),
  secondary: (theme-color(light), theme-color(secondary)),
  white: (theme-color(dark), theme-color(light)),
);

$icon-sizes: (
  lg: 45px,
  md: 32px,
  sm: 22px,
  xs: 16px,
  // You can add more key value pairs,
  // like an intro text for example:
  // intro: 18px,
  //
  // Use it like this:
  // get-value-if-key-exists(lg);,,,,,,,,,,,
);

$z-index: (
  nav: 99,
  modal: 999,
);
