.stepper {
  display: flex;
  justify-content: center;
  padding: 0 20px;

  &__item {
    display: flex;
    align-items: center;

    &__dot {
      content: '';
      @include size(12px);
      background-color: gray-color(500);
      display: inline-block;
      border-radius: 50%;
      border: 2px solid transparent;
      transition: transform .2s, background .2s;
    }

    &.is--active &__dot {
      border-color: theme-color(primary);
      transform: scale(1.5);
      background: white;
    }

    &.is--done &__dot {
      background: color(green);
    }

    &:not(:last-of-type)::after {
      content: '';
      width: 15px;
      height: 2px;
      border-radius: 2px;
      display: inline-block;
      background: gray-color(200);
      
      @include media-breakpoint-up(sm) {
        width: 30px;
        margin: 0 rem(7px);
      }
    }
  }
}