.text-decoration-none {
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.text-headings {
  font-family: $headings-font-family;
}

.text-body {
  font-family: $font-family-sans-serif;
}

.text-max-width {
  max-width: map-get($container-max-widths, xss);
  margin: auto;
}
