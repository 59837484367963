@keyframes alertIn {
  from { opacity: 0; transform: translateX(-100px) }
  to { opacity: 1; transform: translateX(0px) }
}

.alert {
  margin-top: rem(20px);
  background: space-gray-color(100);
  padding: rem(20px);
  border-radius: 6px;
  border: 2px solid space-gray-color(200);
  font-weight: 600;
  line-height: 1.5;
  color: space-gray-color(900);
  animation: alertIn .2s ease-in forwards;
  @include get-font-size(body-sm);
}

@each $color, $value in $theme-colors {
  .alert.alert--#{$color} {
    background-color: lighten($value, 40%);
    border-color: lighten($value, 25%);
    color: darken($value, 5%);
  }
}